import React, { useState, useRef, useEffect } from 'react';
import { shape, func } from 'prop-types';
import BuildButtonAction from './build-button-action';
import { getCookieValue } from '../../../../lib/cookies';
import { constants } from '../../../../utils/constants';

const BuildButtonActionParams = ({ actionProps, buttonProps, onClickHandler }) => {
  const { MSHOPS_D2ID_SUFFIX, MSHOPS_D2ID_LENGTH } = constants;
  const inputRef = useRef(null);

  const [customParams, setCustomParams] = useState([]);

  const submitForm = () => {
    inputRef.current?.click();
  };

  const onClickEvent = (e, component, focus) => {
    if (customParams.length === 0) {
      e.preventDefault();
      const mlDataSessionId = getCookieValue('_mldataSessionId_mshops')?.toString();

      const d2idString = getCookieValue('_d2id')?.toString();
      const d2id =
        d2idString?.length >= MSHOPS_D2ID_LENGTH && d2idString.slice(0, MSHOPS_D2ID_LENGTH).concat(MSHOPS_D2ID_SUFFIX);

      const mshopsGaCid = getCookieValue('_mshops_ga_cid')?.toString();

      const params = [
        mlDataSessionId && { name: 'mshops_mldataSessionId', value: mlDataSessionId },
        d2id && { name: 'mshops_d2id', value: d2id },
        mshopsGaCid && { name: '_mshops_ga_cid', value: mshopsGaCid },
      ];
      setCustomParams(params);
    } else {
      onClickHandler(e, component, focus);
    }
  };

  useEffect(() => {
    if (customParams.length !== 0) {
      submitForm();
    }
  }, [customParams]);

  const renderCustomParams = () =>
    customParams
      .filter(Boolean)
      .map(({ name, value } = {}) => name && value && <input key={name} type="hidden" name={name} value={value} />);

  return (
    <>
      <BuildButtonAction
        onClickHandler={onClickEvent}
        buttonProps={{ ...buttonProps, innerRef: inputRef }}
        {...actionProps}
      />
      {renderCustomParams()}
    </>
  );
};

BuildButtonActionParams.propTypes = {
  actionProps: shape({}).isRequired,
  buttonProps: shape({}).isRequired,
  onClickHandler: func.isRequired,
};

BuildButtonActionParams.defaultProps = {
  actionProps: {},
  buttonProps: {},
  onClickHandler: () => {},
};

export default BuildButtonActionParams;
